/** ***************************************************************************
 * Molecules / Slider / Modal
 *************************************************************************** */

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade';
import dialogPolyfill from 'dialog-polyfill';

class ModalComponent {
  constructor(element) {
    this.element = element;
    this.html = document.querySelector('html');
    dialogPolyfill.registerDialog(this.element);

    this.setUpEventListeners();

    this.image = element.querySelector('.ap-c-modal img');
    this.video = element.querySelector('.ap-c-modal #vimeo-video');
    this.closeButton = element.querySelector('.ap-c-modal .ap-m-close');
    this.closeButton.onclick = (event) => this.close();

    this.element.addEventListener('close', this.onclose);
    document.addEventListener('keyup', this.onkeyup);
  }

  destroy() {
    this.closeButton.onclick = undefined;
    this.element.removeEventListener('close', this.onclose);
  }

  // Methods

  open() {
    if (this.element.open) {
      // prevent HTMLDialogElement.InvalidStateError
      return;
    }
    this.element.showModal();
    this.html.style.overflowX = 'unset';
    disableBodyScroll(this.element, {
      // see https://github.com/willmcpo/body-scroll-lock#reservescrollbargap
      reserveScrollBarGap: true,
    });
    this.closeButton.blur(); // chrome and firefox focus the first button
    document.documentElement.classList.add('ap-m-pause');
  }

  close() {
    this.element.addEventListener('transitionend', this.ontransitionend);
    this.element.classList.add('ap-m-closing');
  }

  // event listeners

  setUpEventListeners() {
    this.onkeyup = (event) => {
      if (event.key === 'Escape') {
        this.close();
      }
    };

    this.onclose = (event) => {
      this.html.style.overflowX = 'hidden';
      enableBodyScroll(this.element);
      document.documentElement.classList.remove('ap-m-pause');
    };

    this.ontransitionend = (event) => {
      if (event.pseudoElement == '::before') {
        // background
        this.element.classList.remove('ap-m-closing');
        this.element.close();
        this.element.removeEventListener('transitionend', this.ontransitionend);
        this.image.style.display = 'block';
        if (this.player) {
          this.player.destroy();
        }
      }
    };
  }
}

export { ModalComponent as default };
