/** ***************************************************************************
 * Molecules / Slider / Slider
 *************************************************************************** */

import Splide from '@splidejs/splide';
import ModalComponent from '../modal/modal';
import Player from '@vimeo/player';

class SliderComponent {
  constructor(element) {
    this.element = element;

    const body = document.querySelector('body');

    this.splide = new Splide(element, {
      // type: 'slide',
      pagination: false,
      drag: 'free',
      gap: '1em',
      autoWidth: true,
      autoHeight: true,
      fixedHeight: '40rem',
      // perPage: 1,
      breakpoints: {
        1080: {
          fixedHeight: '13.125rem', // 210px
          // autoWidth: false,
          // perPage: 1,
        },
        1280: {
          fixedHeight: '22.5rem',
        },
        1440: {
          fixedHeight: '26.75rem',
        },
        1700: {
          fixedHeight: '30.125rem',
        },
        1920: {
          fixedHeight: '35rem',
        },
      },
    });
    this.splide.mount();

    // render component's items on image load
    const listElements = document.querySelectorAll(
      '.splide__list li[data-video]'
    );
    listElements.forEach((li) => {
      const image = li.querySelector('img');
      const overlay = li.querySelector('.video-overlay');
      if (image.complete) {
        overlay.style.display = 'block';
      }
      image.onload = () => {
        overlay.style.display = 'block';
      };
    });

    const dialogFragment = this.buildDialog();
    body.appendChild(dialogFragment);
    this.dialog = new ModalComponent(body.lastElementChild);

    this.dialogItems = this.element.querySelectorAll(
      '.splide .splide__list li'
    );
    if (this.dialogItems.length) {
      this.dialogItems.forEach((item, index) => {
        item.onclick = (event) => {
          event.preventDefault();
          if (item.dataset.video) {
            const regex =
              /(?:https?:\/\/)?(?:www\.)?(?:player\.)?vimeo\.com\/(?:video\/|moogaloop\.swf\?clip_id=)?(\d+)(?:.*)/;
            const match = item.dataset.video.match(regex);

            // Se corrisponde, ottieni l'ID del video
            const videoId = match ? match[1] : null;

            const options = {
              id: videoId,
              autoplay: true,
            };

            const player = new Player(this.dialog.video, options);
            this.dialog.player = player;
            this.dialog.video.style.display = 'block';
            this.dialog.image.style.display = 'none';
          } else {
            const image = item.querySelector('img');
            this.dialog.image.src = image.src;
            this.dialog.video.style.display = 'none';
          }

          this.dialog.element.querySelector('.currentItemPosition').innerText =
            index + 1;
          this.dialog.open();
        };
      });
    }

    this.splide.on('moved scrolled', () => {
      this.signCurrentItemPosition();
    });
  }

  signCurrentItemPosition() {
    const currentItemPositionElements = document.querySelectorAll(
      '.currentItemPosition'
    );
    const currentItemIndex = this.splide.Components.Controller.getIndex();
    const currentItemPosition = currentItemIndex + 1;
    currentItemPositionElements.forEach((element) => {
      element.innerText = currentItemPosition;
    });
  }

  destroy() {
    this.dialogItems.forEach((item) => {
      item.onclick = undefined;
    });
    this.dialog.element.remove();
    this.dialog.destroy();
  }

  buildDialog() {
    const modalTemplate = document.getElementById('ap-c-modal-template');
    return modalTemplate.content.cloneNode(true);
  }
}

window.addEventListener('apus:init', () => {
  const selector = '.splide';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      window.slider = new SliderComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.SliderComponent) {
        element.SliderComponent.destroy();
      }
    });
  }

  window.APUS.register(selector, initialize, destroy);

  initialize();
});
