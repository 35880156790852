/** ***************************************************************************
 * Organisms / Section
 *************************************************************************** */

import { gsap } from 'gsap';
const IS_FIREFOX = navigator.userAgent.toLowerCase().includes('firefox');

class ApusSection {
  constructor(element) {
    this.element = element;
    this.CIRCLE_PATH = this.element.querySelector('#circlePath');
    this.TEXT_PATH = this.element.querySelector('#textPath');
    this.TEXT = this.element.querySelector('#text');
    const sectionDestination = this.element.getAttribute(
      'data-section-destination'
    );

    // const hoverItems = document.querySelectorAll('.cursor-hover-item');
    const defaultCursor = this.element.querySelectorAll('.default-cursor');
    this.circleSvg = this.element.querySelector('svg#text-cursor');
    this.cursorOuter = this.element.querySelector('.cursor--large');

    this.mouseX = 0;
    this.mouseY = 0;

    this.hoverEffectDuration = 0.3;
    this.isHovered = false;

    this.initialize();

    this.element.addEventListener('click', () => {
      if (this.isHovered) {
        window.location.href = sectionDestination;
      }
    });

    /* hovering */
    this.element.addEventListener(
      'pointerenter',
      this.handlePointerEnter.bind(this)
    );
    this.element.addEventListener(
      'pointerleave',
      this.handlePointerLeave.bind(this)
    );

    // per evitare di mostrare il cursore sopra gli elementi con classe '.default-cursor'
    defaultCursor.forEach((item) => {
      item.addEventListener('pointerenter', this.handlePointerLeave.bind(this));
      item.addEventListener('pointerleave', this.handlePointerEnter.bind(this));
    });

    /* cursoring */
    document.body.addEventListener(
      'pointermove',
      this.updateCursorPosition.bind(this),
      { passive: true }
    );

    document.body.addEventListener('wheel', this.initialize.bind(this));

    this.updateCursor();

    window.addEventListener('resize', () => {
      this.initialize();
    });
  }

  initialize(e) {
    const OPTIONS = {
      // text: this.updateCursorText(this.element),
      size: 10,
      radius: Math.min(this.cursorOuter.offsetWidth / 2, 50),
      showPath: false,
      spread: true,
      inside: true,
    };

    const VIEWBOX = 100;

    this.TEXT.setAttribute('font-size', OPTIONS.size);
    const PATH = `
        M ${VIEWBOX * 0.5 - OPTIONS.radius}, ${VIEWBOX * 0.5}
        a ${OPTIONS.radius},${OPTIONS.radius} 0 1,${OPTIONS.inside ? 0 : 1} ${
      OPTIONS.radius * 2
    },0
        ${OPTIONS.radius},${OPTIONS.radius} 0 1,${OPTIONS.inside ? 0 : 1} -${
      OPTIONS.radius * 2
    },0
      `;
    if (OPTIONS.spread)
      this.TEXT_PATH.setAttribute(
        'textLength',
        Math.floor(Math.PI * 2 * OPTIONS.radius)
      );
    else this.TEXT_PATH.removeAttribute('textLength');
    OPTIONS.text = this.updateCursorText(this.element);
    this.CIRCLE_PATH.setAttribute('d', PATH);
    this.TEXT_PATH.innerHTML = OPTIONS.text;
    this.CIRCLE_PATH.style.setProperty('--show', OPTIONS.showPath ? 1 : 0);

    if (e) this.updateCursorPosition(e);
  }

  updateCursorPosition(e) {
    this.mouseX = e.clientX;
    this.mouseY = e.clientY;

    if (
      !this.isHovered &&
      this.element.contains(
        document.elementFromPoint(this.mouseX, this.mouseY)
      ) &&
      // per evitare di mostrare il cursore sopra gli elementi con classe '.default-cursor'
      !document
        .elementFromPoint(this.mouseX, this.mouseY)
        .closest('.default-cursor')
    ) {
      this.handlePointerEnter(e);
    } else if (
      // per fare in modo che il cursore cambi automaticamente allo scroll
      e.type === 'wheel' &&
      !this.element.contains(
        document.elementFromPoint(this.mouseX, this.mouseY)
      )
    ) {
      this.handlePointerLeave();
    }
  }

  updateCursor() {
    this.circleSvg.style.top = this.mouseY - 75 + 'px';
    this.circleSvg.style.left = this.mouseX - 75 + 'px';

    gsap.to(this.cursorOuter, {
      duration: 0.15,
      x: this.mouseX,
      y: this.mouseY,
    });

    requestAnimationFrame(this.updateCursor.bind(this));
  }

  handlePointerEnter(e) {
    this.isHovered = true;

    gsap.to([this.circleSvg, this.cursorOuter], this.hoverEffectDuration, {
      opacity: 1,
    });
  }

  handlePointerLeave() {
    this.isHovered = false;

    gsap.to([this.circleSvg, this.cursorOuter], this.hoverEffectDuration, {
      scale: 1,
      opacity: 0,
    });
  }

  /* textering */
  updateCursorText(textEl) {
    const cursorTextRepeatTimes = textEl.getAttribute(
      'data-cursor-text-repeat'
    );
    const cursorText = this.returnMultipleString(
      textEl.getAttribute('data-cursor-text'),
      cursorTextRepeatTimes
    );
    return cursorText;
  }

  returnMultipleString(string, count) {
    let s = '';
    const blankSpaces = this.getBlankSpaces(string, count);
    for (let i = 0; i < count; i++) {
      for (let step = 0; step < blankSpaces; step++) {
        s += '&nbsp;';
      }
      s += string;
      for (let step = 0; step < blankSpaces; step++) {
        s += '&nbsp;';
      }
    }
    return s;
  }

  getBlankSpaces(string, count) {
    if (count > 3) return 1;

    if (IS_FIREFOX) {
      let multiplier = 1;
      switch (string.length) {
        case 6: // IMPETO
          multiplier = 5.04;
          break;
        case 9: // STRUMENTO
          multiplier = 5.88;
          break;
        case 10: // ESPERIENZA
          multiplier = 5.35;
          break;
      }
      return (
        this.TEXT_PATH.getAttribute('textLength') / 3 / multiplier -
        string.length
      );
    }

    switch (string.length) {
      case 6: // IMPETO
        return 7;
      case 9: // STRUMENTO
        return 3;
      case 10: // ESPERIENZA
        return 3;
      default:
        return 6;
    }
  }
}

window.addEventListener('apus:init', () => {
  const selector = '.ap-c-section';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.apSection = new ApusSection(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.apSection) {
        element.apSection.destroy();
        element.apSection = undefined;
      }
    });
  }

  window.APUS.register(selector, initialize, destroy);

  initialize();
});

export { ApusSection };
