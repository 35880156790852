/******************************************************************************
 * Molecules / Video
 *****************************************************************************/

import Player from '@vimeo/player';
import ModalComponent from '../slider/modal/modal';

class VideoComponent {
  constructor(element) {
    this.element = element;

    const body = document.querySelector('body');

    const dialogFragment = this.buildDialog();
    body.appendChild(dialogFragment);
    this.dialog = new ModalComponent(body.lastElementChild);

    const videoOverlay = this.element.querySelector('.video-overlay');

    videoOverlay.onclick = (event) => {
      event.preventDefault();
      if (this.element.dataset.video) {
        // URL del video
        const url = this.element.dataset.video;

        // Utilizzando espressioni regolari per estrarre l'ID del video
        const regex =
          /(?:https?:\/\/)?(?:www\.)?(?:player\.)?vimeo\.com\/(?:video\/|moogaloop\.swf\?clip_id=)?(\d+)(?:.*)/;
        const match = url.match(regex);

        // Se corrisponde, ottieni l'ID del video
        const videoId = match ? match[1] : null;

        const options = {
          id: videoId,
          autoplay: true,
        };

        const player = new Player('vimeo-video', options);
        this.dialog.player = player;
        this.dialog.video.style.display = 'block';
        this.dialog.image.style.display = 'none';
        // video.style.display = 'block';
        // image.style.display = 'none';
        // videoOverlay.style.display = 'none';
      } else {
        // video.style.display = 'none';
        // image.style.display = 'block';
        // videoOverlay.style.display = 'block';
      }
      // const currentItemPositionElement = this.dialog.element.querySelector(
      //   '.currentItemPosition'
      // );
      // currentItemPositionElement.innerText = index + 1;
      this.dialog.open();
    };
  }

  buildDialog() {
    const modalTemplate = document.getElementById('ap-c-modal-video_template');
    return modalTemplate.content.cloneNode(true);
  }

  destroy() {
    playButtons.forEach((button) => {
      button.onclick = undefined;
    });
  }
}

window.addEventListener('apus:init', () => {
  const selector = '.ap-c-video';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      const component = new VideoComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.VideoComponent) {
        element.VideoComponent.destroy();
      }
    });
  }

  window.APUS.register(selector, initialize, destroy);

  initialize();
});
